.container {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    /* padding: 5px; */
}

.headerPadding{
    padding:5px;
}

.infoHeaderContainer{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 45px;
}

.drop :hover{
    box-shadow: 0px 8px 14px -4px rgba(0,0,0,0.2);
      transform: translate(0px, -3px);
}

.dropdown{
    color: #000; 
    border-color: #fff;
 
    margin-top: -10px; 
    background: #fff;
    border-radius: 5px;
    background-color:#F8F8F8;
    padding:10px
}

.borderLeft{
    color:#888888;
    font-weight:bolder; 
    width:14px;
    right:198px;
    height:10px
}

.margin5{
    margin: 5px;
}

.img{
    margin-top: 15px;
}

.seachContainer{
    padding-left: 25px;
    padding-right: 25px;    
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    padding-top: 10px;
    padding-bottom: 10px;
}


.margin {
    margin-left: 15px;
    margin-right: 15px;
}

.leftPanel {
    display: flex;
    justify-content: flex-start;
}

.rightPanel {
    display: flex;
    justify-content: flex-end;
}

.logo {
    height: 80px;
    width: 161px;
}

.border {
    border-left: 2px solid lightgrey;
}

.borderNoMargin{
    border-bottom: 1px solid lightgray;
}

.searchBar {
    display: flex;
}

.flex {
    display: flex;
    justify-content: flex-start;
}

.padding15{
    padding:15px;
}

.padding20{
    padding: 20px;
}

.dropdownContent{
    padding-left: 5px;
    padding-right: 5px;
}

.badge{
    font-size: 12px;
    line-height: 14.63px;
}

.login{
    line-height: 19.5px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #888888;
}

.border{
    margin-top: 2px;
    border-bottom: 1px solid lightgray;
}

.marginTop {
    margin-top: 10px;
}

.icons {
    margin-right: 2px;
    height: 16.8px;
    width: 16.8px
}

.DesktopView {
    display: flex;
}

.searchButton {
    margin-left: 10px;
}

.white {
    color: #fff
}

.loginSignup{
    width: 106px;
    height: 17px;
    top:13px;
    left:1265px;
    font-weight:400;
    font-size: 14px;
    line-height:17.07px;
}

.Search {
    padding: 10px;
    border-radius: 20px;
    background-color: #F8F8F8;
    border: 1px solid #E9E9E9;
    margin-right: 2px;
    width: 394px;
}

.searchButton {
    background-color: #B12926;
    padding: 10px;
    border-radius: 50%;
    border: none;
}

.SearchIcon {
    color: #fff;
    height: 21px;
    width: 21px;
}

.border{
    border-bottom: 1px solid lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.locationIcon {
    margin-right: 10px;
    height: 18px;
    width: 14px;
}

.fontStyle {
    font-size: 16px;
    font-weight: 600;
    line-height: 17.07px;
    color:#888888;
}

.LoginfontStyle{
    font-size: 18px;
    font-weight: 600;
    line-height: 17.07px;
    color:#888888;
    cursor: pointer;
}

.mailIcon {
    margin-right: 10px;
    height: 13px;
    width: 17px;
    margin-top: 3px;
}

.callIcon {
    margin-right: 10px;
    height: 17px;
    width: 17px;
    margin-top: 3px;
}

.menuHeaderContainer{
    padding-left: 25px;
    padding-right: 25px;
    border-bottom: 1px solid lightgray;
    height: 50px;
}

.menuFont {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    margin-top: 5px;
    /* margin-left: 20px;
    margin-right: 20px; */
    line-height: 17.07px;
    
}

.saperation{
    border-left: 2px solid lightgray;
    margin-left: 20px;
    margin-right: 20px;
   height: 80%
}

.MobileView {
    display: none;
}

.menu{
    margin-left: 30px;
    margin-top: 10px;
    width: 100%;
}

/* other perfumers*/

.perfumerButton{
    background-color:#9F7D2D; 
    width: 287px;
    height: 50px;
    padding: 5px;
}

.listStyleNone{
    list-style: none;
}

.MobileViewStyle{
    display: flex;
}

.buttonText{
    margin:10px;
    color:#fff; 
    font-size:15px;
    line-height: 18.29px;
}

.buttonContent{
    margin:10px;
    color:#fff; 
    /* width:10px; */
    /* height: 5px; */
    margin-top: 3px;
}

.downArrow{
    margin:10px;
    color:#fff; 
    align-self: flex-end;
    /* margin-top: 3px; */
}

.space{
    justify-content: space-between;
}



@media screen and (max-width: 880px) {

    .menuFont {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        margin-top: 3px;
        margin-left: 10px;
        margin-right: 10px;
        line-height: 17.07px;
        
    }

    .perfumerButton{
        background-color:#9F7D2D; 
        width: 287px;
        height: 50px;
        padding: 5px;
        margin-top: -10px;
    }


    .container {
        padding: 0;
    }

    .leftPanel {
        display: flex;
        flex-wrap: wrap;
    }

    .SearchBarMobile {
        padding: 10px;
        border-radius: 20px;
        background-color: #F8F8F8;
        border: 2px solid #F8F8F8;
        margin-right: 2px;
        width: 100%;
    }

    .SearchInput{
        width: 90%;
        background-color: #F8F8F8;
        border: 2px solid #F8F8F8;
        border-radius: 20px;
        padding: 10px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .SearchIcon {
        color: #fff;
        height: 10px;
        width: 10px;
    }

    .DesktopView {
        display: none;
    }

    .MobileView {
        display: block;
        margin-top: 20px;
    }

    .drawerButton{
        display: block;
        margin-top: 0px;
        margin-right: 5px;
    }

    .MobileViewStyle{
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
    }
    .margin {
        margin-left: 5px;
        margin-right: 5px;
    }
    .searchBar{
        display: flex;
        justify-content: center ;
    }
    .img{
        margin-top: 5px;
    }
}

@media screen and (max-width:500px) {
    .SearchInput{
        width: 80%;
        background-color: #F8F8F8;
        border: 2px solid #F8F8F8;
        border-radius: 20px;
        padding: 10px;
        margin-top: 15px;
        margin-bottom: 15px;
    }    
}

@media screen and (max-width:1100px) {
    .menuFont {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        margin-top: 3px;
        margin-left: 0px;
        margin-right: 0px;
        line-height: 17.07px;
        
    }    
}