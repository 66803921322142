.container {
    border:1px solid lightgray;
}

.header {
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
}

.wishCon{
    border:1px solid lightgray;
    margin-top: 20px;
    margin-bottom: 50px;
}

.heading {
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
}

.paddingLeft {
    padding-left:0px;
}

.paddingRight {
padding-right: 0px;
}

.margintop10{
    margin-top: 0px;
}

.addAddress{
    background-color:#FFE7E7;
    color:#B12926;
    padding: 5px;
    height: fit-content;
    border:none;
    margin-top: 12px;
    margin-right: 10px;
}

.address-1 {
    background-color: #E8F1FA;
    padding: 10px;
    border-radius: 5px;
}

.primaryButton {
    border: none;
    background-color: #E8F1FA;
    color: #2875DE;
}

.width70 {
    width:70% ;
}

.secondaryButton {
    border: none;
    background-color: #FFE7E7;
    color: #B12926;
}

.address-2 {
    background-color: #FFE7E7;
    padding: 10px;
    border-radius: 5px;
}

.address-details {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
}

.marginBottom {
    margin-bottom: 20px;
}

.address-head {
    font-size: 18px;
    font-weight: 500;
    color:#888888;
}

.Modal{
    background-color: #fff;
    /* padding: 20px; */
    width: 90%;
    height: 85%;
}

.address-button{
    border: none;
    color: #fff;
    background-color: #B12926;
    border-radius: 20px;
    padding:10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
    float:right; 
    margin-bottom:20px;
}

.primaryEdit {
    border: none;
    border-radius: 50%;
    padding: 10px;
    background-color: #FFE7E7;
}

.right{
    float:right;
}

.marginLeft {
    margin-left: 5px;
}

.marginTop {
    margin-top: 10px;
}

.borderBottom{
    border-bottom: 1px solid lightgray;
}

.selectMargin{
margin-top: 85px;
}

.checkboxMargin{
    margin-top:10px;
}

.addressBox {
    padding:20px;
}

.border{
    border:1px solid lightgray;
}

@media screen and (max-width:880px) {
    .margintop10{
        margin-top:60px;
    }
    .selectMargin{
        margin-top: 10px;
    }
    .paddingLeft{
        padding-left: 2px;
        padding-right: 0px;
    }
    .paddingRight {
        padding-right: 2px;
        padding-left: 0px;
        }
}