.container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.box {
    margin-top: 0px;
}

.boxHeight {
    height: 259px;
    width: 302px;
}

.boxHeight:hover{
    box-shadow: 20px 20px 20px 20px rgba(0.2,0.2,0.2,0.2);
    transform: translate(0px, 10px);
}

.boldText {
    font-weight: bold;
    margin-left: 5px;
}

.heading {
    /* margin-top: 220px; */
    color: #fff;
    /* position: absolute; */
    text-align: center;
    /* margin-left: 10%; */
    /* font-weight: 600;
    font-size: 24px;
    line-height: 29px; */

}

.heading2{
    font-size: 40px;
    text-align: center;
    color: #fff;

    margin-top: -20%;
}

@media screen and (max-width:880px) {
    .box {
        margin-top: 20px;
    }
    .boxHeight {
        height: auto;
        width: auto;
        max-width: 150px;
        max-height: 150px;
        min-width: 150px;
        min-height: 150px;
    }
}

@media screen and (max-width:1400px) {
    .boxHeight {
        height: auto;
        width: auto;
        max-width: 200px;
        max-height: 200px;
    }    
}



