.container {
    border:1px solid lightgray;
}

.header {
    border-bottom: 1px solid lightgray;
}

.heading {
    margin-left: 20px;
}

.referal{
    margin-top: 20%;
    justify-content: center;
}

.referalText {
    margin-top: 10px;
}

.referalCode {
    margin-left:10px;
    background:pink; 
    color:#B12926;
    border-radius:20px;
    padding-left:20px; 
    padding-right:20px;
    padding-top:10px;
    padding-bottom:10px;
}

.codeBox {
    display: flex;
    border: 1px solid lightgray; 
    padding:5px;
    border-radius:20px;
    justify-content:space-between; 
    margin-top:30px
}

.input {
    border:none
}

.copyBotton {
    padding-top:4px; 
    padding-bottom:4px;
    padding-left:10px; 
    padding-right:10px;
    background:#9F7D2D;
    border-radius:20px;
    margin-left:20px;
    float:right;
    color:#fff;
    width:30%;
}

.img{
    height: 350px;
    width: 350px;
}

@media screen and (max-width:880px) {
    .img{
        height: auto;
        width: 100%;
    }        
}