    .container {
        padding: 40px;
        margin-top: 50px;
    }
/* 
    .mailIcon{
        sx={{fontSize:25, marginTop:'4px', color:'#fff'}}
    } */

    .banner {
        /* width: 668px; */
        min-height: 348px;
        background-repeat: no-repeat;
    }

    .mtmobile{
        margin-top: 0px;
    }

    .bannerPadding {
        padding: 80px;
        text-align: left;
    }

    .banner2Padding {
        padding: 80px;
        text-align: center;
    }

    .text {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        width: 55%;
        line-height: 21px;
    }

    .Emailtext {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        width: 80%;
    }

    .deliverText {
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        width:40%
    }

    .marginTop {
        margin-top: -10px;
    }

    .dealText {
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
    }

    .learnMoreButton {
        width: 140px;
        height: 40px;
        left: 110px;
        top: 3790px;
        border-radius: 31px;
        background: #fff;
        color: #000;
        border: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

    }

    .inputContainer {
        display: flex;
        background: #fff;
        /* padding: 10px; */
        border-radius: 50px;
        justify-content: space-between;
        margin-top: 30px;
        width: 450px;
        /* margin-left: 10%; */
    }

    .input {
        /* display: flex; */
        background: #fff;
        padding: 10px;
        border-radius: 50px;
        justify-content: space-between;
        /* margin-top: 30px; */
        /* width: 300px; */
        /* margin-left: 10%; */
        border: none;
        padding-left: 25px;
    }

    .mailBox {
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 20px;
        padding-right: 20px;
        background: #9F7D2D;
        border-radius: 20px;
        margin-left: -20px;
        float: right;
    }

    .mailCon {
        background-color: #9F7D2D;
        height: 35px;
        margin-right: 5px;
        margin-top: 3px;
        border-radius: 50px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .mailIcon {
        font-size: 25px;
        color: #fff;
        margin-top: 10px;
    }

    .borderNone {
        border: none;
    }

    .borderNone:focus {
        border: none;
    }

    .borderNone:checked {
        border: none;
    }

    .marginTop {
        margin-top: 0px;
    }

    @media screen and (max-width:990px) {

        .banner {
            width: 100%;
            height: auto;
            min-height: 200px;
        }

        .inputContainer {
            display: flex;
            background: #fff;
            padding: 0px;
            border-radius: 50px;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;
            height: auto;
        }

        .container {
            padding: 5px;
            margin-top: 0px;
        }

        .mtmobile{
            margin-top: 20px;
        }

        .banner2Padding {
            padding: 80px;
            text-align: center;
        }

        .bannerPadding {
            padding: 80px;
            text-align: center;
        }

        .bannerPadding {
            /* padding-top: 30px;
            margin-bottom: 30px;
            padding-left: 30px; */
            padding: 10px;
            text-align: left;
            max-height: 500px;
        }
        
        .center{
            text-align: center;
            margin-top: 20px;
            /* margin-bottom: 10px; */
        }

        .banner2Padding {
            padding: 80px;
            text-align: left;
        }

        .deliverText {
            font-weight: 600;
            font-size: 15px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            padding-top: 10px;
        }

        .text {
            font-style: normal;
            font-weight: 600;
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            
        }
        .dealText {
            font-weight: 600;
            font-size: 15px;
            line-height: 36px;
        }

        .Emailtext {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            width: 100%;
            text-align: center;
        }
    }

    @media screen and (max-width:1300px) {

        .banner {
            width: 100%;
            height: 100%;
        }

        .inputContainer {
            display: flex;
            background: #fff;
            padding: 0px;
            border-radius: 50px;
            justify-content: space-between;
            margin-top: 30px;
            width: 100%;
            height: auto;
        }

    }

    @media screen and (max-width:1275px) and (min-width:800px) {

        .banner {
            width: 100%;
            height: 100%;
        }
        .bannerPadding {
            padding-top: 60px;
            margin-bottom: 50px;
            padding-left: 40px;
            text-align: left;
            max-height: 400px;
            padding-bottom: 10px;
        }
        .banner2Padding {
            padding: 60px;
            text-align: center;
        }

        .bannerPadding {
            padding: 60px;
            text-align: center;
        }

    }

    @media screen and (max-width:988px) {
        .inputContainer {
            display: flex;
            background: #fff;
            padding: 0px;
            border-radius: 50px;
            justify-content: space-between;
            margin-top: 30px;
            width: 100%;
            max-width: 350px;
            height: 20%;
        }
    }

    @media screen and (max-width:550px) {
        .inputContainer {
            display: flex;
            background: #fff;
            padding: 0px;
            border-radius: 50px;
            justify-content: space-between;
            margin-top: 20px;
            width: 100%;
            max-width: 300px;
            height: 20%;
        }
        .banner2Padding {
            padding: 20px;
            text-align: center;
        }
        .bannerPadding {
            padding: 20px;
            text-align: center;
        }
    }

    @media screen and (max-width:767px) {
        .marginTop {
            margin-top: 30px;
        }

        .dealText {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
        }
    }