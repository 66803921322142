.carSpringImg {
    max-width: 280px;
    max-height: 280px;
    margin-top: 50px;
}

.automativeContainer {
    min-height: 500px;
    background-color: #F3F4F8;
    padding: 20px;
    padding-bottom: 0px;
    width: 920px;
    float: right;
}

.TabBorder{
    border-bottom: 2px solid lightgray;
}

.container {
    border:1px solid lightgray;
    margin-top: 20px;
    margin-bottom: 50px;
}


.mobileSize{
    display: contents;
}

.mobileSizeDisplay{
    display: none;
}

.autoText1 {
    font-size: 18px;
    margin-top: 150px
}

.autoText2 {
    font-size: 18px;
    margin-top: -20px;
}

.nonActive {
    margin-left: 20px;
    margin-right: 20px;
    color: #000;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}

.active {
    margin-left: 20px;
    margin-right: 20px;
    color: red;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}

.heading {
    margin: 20px;
    font-size: 30px;
}

.container {
    border:1px solid lightgray;
    font-size: 15px;
}

.header {
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
}

.bookApt {
    background-color: #000C22;
    width: 100%;
    padding: 12px;
    color: white;
    float: left;
    /* font-family: 'Sarabun', sans-serif; */
    /* margin-left: -12px; */
}
.viewMoreBtn {
    background-color: #C2212B;
    height: 50px;
    width: 156px;
    margin-top: 20px;
    /* height: 25%; */
}

.automotiveHeading {
    font-weight: 300;
    font-size: 50px;
    /* font-family: 'Sarabun', sans-serif; */
    line-height: 65px;
    margin-top: 20px;
}

.searchBtn {
    background-color: #FF9C00;
    width: 90%;
    margin-left: 5%;
    border: none;
}

.modal {
    top: 30%;
    width: 380px;
    left: 63.8%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

.modalContainer {
    /* padding: 20px; */
    /* height: 20px; */
    overflow:hidden;
    overflow-y: auto;
    max-height: 150px;
    /* overflow-y: scroll; */
}

.modalFuelContainer {
    padding: 20px;
    height: 200px;
    overflow: hidden;
    overflow-y: scroll;
}

.width100 {
    width: 100%;
}

.searchImg {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.searchCar {
    border: none;
    height: 40px;
    margin-left: 10px;
}

.menufImg {
    width: 60px;
    height: 60px;
    margin-top: 10px;
}

.modelImg {
    width: 100px;
    height: 60px;
    margin-top: 10px;
}

.fuelImg {
    width: 60px;
    height: 60px;
    margin-top: 10px;
}

.mt {
    display: block;
}

.compHeading {
    font-weight: 700;
    font-size: 50px;
    /* font-family: 'Sarabun', sans-serif; */
    line-height: 65px;
    margin-top: -10px;
}

.form{
    padding: 10px;
    margin-top: 40px;
}

.automotiveContainerForm {
    background-color: #F3F4F8;
    height: 500px;
    width: 350px;
    float: left;
    /* font-family: 'Sarabun', sans-serif; */
}

.faqContainer {
    text-align: -webkit-center;
}

.flex {
    display: flex;
}

.map {
    margin-bottom: 20px;
}

.blogCard {
    width: 25rem;
    margin: 15px;
}

.AboutImg {
    height: 300px;
    width: 600px;
    margin-top: 30px;
}

.p50 {
    padding: 50px;
}

.aboutCard {
    width: 18rem;
    height: 18rem;
}

.mt100 {
    margin-top: 100px;
}

.aboutHeading {
    font-size: 80px;
}

.ml15{
    margin-left:15px ;
}

.faqWidth{
    width:80%;
}

.CardRead{
    color: #C2212B; 
    text-decoration: none;
}

.ml5{
    margin-left: 5px;
}

.mr5{
    margin-right: 5px;
}

.textDecor{
    text-decoration: none;
}

.orderCont{
    border: 1px solid #D3D3D3; 
    padding-bottom: 7px; 
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.5);
}

.track{
    background-color: #C2212B; 
    border-radius: 0px;
    color:#fff;
    font-size: 15px;
    border: none;
    padding: 5px;
    margin-top: 6px;
}

.req{
    background-color: #C2212B; 
    color: #fff; 
    border-radius: 0px;
    padding: 5px;
    border: none;
    /* font-size: 15px; */
    padding-left: 15px;
    padding-right: 15px;
    /* padding-left: 5px; */
    /* margin-left: -10px; */
}

.return{
    margin-top:5px; 
    border-radius: 0px;
    border: none;
    padding: 5px;
}

@media screen and (max-width:1300px) {
    .automativeContainer {
        height: auto;
        background-color: #F3F4F8;
        width: 100%;
        float: none;
    }

 

    .carSpringImg {
        width: 100%;
        height: auto;
    }

    .compHeading {
        font-weight: 700;
        font-size: 40px;
        /* font-family: 'Sarabun', sans-serif; */
        line-height: 65px;
        margin-top: -10px;
    }

    .automotiveContainerForm {
        background-color: #F3F4F8;
        height: auto;
        width: 100%;
        float: none;
        /* font-family: 'Sarabun', sans-serif; */
        margin-top: 0px;
    }

    .bookApt {
        background-color: #000C22;
        color: white;
        height: auto;
        float: none;
        width: 100%;
        /* font-family: 'Sarabun', sans-serif; */
        /* margin-left: -12px; */
    }

    .AboutImg {
        height: auto;
        width: 100%;
        margin-top: 30px;
    }
}

@media screen and (max-width:550px) {
    .automativeContainer {
        height: auto;
        background-color: #F3F4F8;
        width: 100%;
        float: none;
        padding: 20px;
    }

    .bookApt {
        background-color: #000C22;
        color: white;
        height: auto;
        width: 100%;
        /* font-family: 'Sarabun', sans-serif; */
    }

    .map {
        width: 100%;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mt {
        display: none;
    }

    .blogCard {
        width: auto;
        margin: 0px;
    }

    .blogContainer {
        margin-top: 20px;
    }

    .modal {
        top: 0%;
        width: 370px;
        left: 0%;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    }

    /* .modalContainer {
        padding: 20px;
        height: 280px;
        overflow: auto;
        overflow-y: scroll;
    } */

    .modalFuelContainer {
        padding: 20px;
        height: 200px;
        overflow: hidden;
        overflow-y: scroll;
    }

    .searchBtn {
        margin-bottom: 20px;
    }

    .AboutImg {
        height: auto;
        width: 100%;
        margin-top: 30px;
    }

    .CarImg {
        width: 100%;
        height: auto;
    }

    .aboutCard {
        width: 100%;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }


    .mt100 {
        margin-top: 20px;
    }

    .aboutHeading{
        font-size: 40px;
    }
}

@media screen and (max-width:768px) {
    .mobileSize{
        display: none;
    }
    .mobileSizeDisplay{
        display: contents;
    }
    .automotiveHeading{
        font-size: 15px;
        margin-top: 0px;
        line-height: 20px;
    }   
    .autoText1 {
        font-size: 12px;
        margin-top: 15px;
    }
    .viewMoreBtn {
        background-color: #C2212B;
        height: auto;
        font-size: 12px;
        width: auto;
        /* height: 25%; */
    }
    .carSpringImg {
        max-width: 150px;
        max-height: 150px;
        margin-top: 15px;
    }
    .automativeContainer {
        min-height: auto;
        background-color: #F3F4F8;
        padding: 20px;
        padding-bottom: 15px;
        padding-top: 0px;
        float: right;
    }
    .form{
        padding: 10px;
        margin-top: 0px;
    }
    .flex {
        display: block;
    }
    .ml15{
        margin-left:0px ;
    }
    .automotiveContainerForm {
        background-color: #F3F4F8;
        height: auto;
        width: 100%;
        float: none;
        /* font-family: 'Sarabun', sans-serif; */
        margin-top: 50px;
    }

}

@media screen and (max-width:1400px) {
    .blogCard {
        width: auto;
        margin: 10px;
    }
    
}