.container {
    padding: 30px;
}

.ReviewHeading {
    background-color: #FBFBFB;
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    padding-left: 15px;
}

.img {
    height: 114px;
    width: 95px;
}

.price {
    font-size: 26px;
    color: #3A9046;
    font-weight: 600;
}

.marginLeftRight {
    margin-left: 5;
    margin-right: 5;
}

.border {
    border-bottom: 2px solid lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.DetailsContainer {
    margin-top: 20px;
    background-color: #F9F9F9;
    padding: 20px;
    width: 80%;
    float: right;
}

.questionIcon {
    margin-top: 10px;
    margin-left: 10px;
}

.marginTop {
    margin-top: 10px;
}

@media screen and (max-width:880px) {
    .container {
        padding: 10px;
    }        
    .ReviewHeading {
        padding: 0px;
    }
    .DetailsContainer {
        margin-top: 20px;
        width: 80%;
        padding:-10px;
        margin-right: 10%;
    }
    .img {
        height: auto;
        width: 100%;
    }
}