.thankImg{
    margin-top:30px; 
    width:150px;
    }
    
    .name{
        margin-left: 10px;
        margin-top: 0px;
    }
    
    .thankCon{
        border:5px solid blue;
    }
    
    .font60{
        font-size: 60px;
    }
    
    .border{
        border-top:5px solid lightgray
    }
    
    .textLeft{
        text-align: left;
    }
    
    .orderId{
        color:#000; 
        margin-left:68px
    }
    
    .left{
        text-align: left;
    }
    .dateTime{
        color:#000; 
        margin-left:12px
    }

    .ml{
        margin-left: 10px;
    }
    
    .amount{
        color:#000; 
        margin-left:42px
    }
    
    .payMode{
        color:#000; 
        margin-left:30px
    }
    
    .deliver{
        color:#000; 
        margin-left:15px
    }
    
    .ml10{
        margin-left: 15px;
    }
    
    .serId{
        color:#000; 
        margin-left:22px
    }
    
    .btn{
        background-color:#000;
        border: none;
    }
    .serId{
        color:#000; 
        margin-left:22px
    }
    
    .serName{
        color:#000; 
        margin-left:75px
    }
    
    .carmake{
        color:#000; 
        margin-left:30px
    }
    
    .pickup{
        color:#000; 
        margin-left:60px
    }
    
    .garageName{
        color: #000;
        margin-left: 65px;
    }
    
    .garageAdd{
        color: #000;
        margin-left: 50px;
    }
    
    @media screen and (max-width:990px) {
        
        .font60{
            font-size: 30px;
        }
        
    }