.container {
    font-family: montserrat;
}

.content {
    padding: 40px
}

.help {
    padding: 20px;
    text-align: center;
}

.helpHeading {
    font-weight: 700;
}

.helpContainer {
    display: flex;
    width: 40%;
    background: #fff;
    padding: 5px;
    border-radius: 20px;
    justify-content: space-between;
    margin-left: 30%;
    margin-top: 30;
    border: 1px solid lightgray;
}

.ques {
    padding: 50px;
    background: #FFE7E7;
    text-align: center;
}

.support {
    text-align: center;
}

.ExploreButton{
    width:80%; 
    font-size:12px; 
    padding:8px; 
    padding-left:20px; 
    padding-right:20px; 
    background:#B12926; 
    color:#fff; 
    border-radius:20px; 
    border:none;
}

.qaButton{
    width:50%; 
    font-size:12px; 
    padding:8px; 
    padding-left:20px; 
    padding-right:20px; 
    background:#B12926; 
    color:#fff; 
    border-radius:20px; 
    border:none;
}

.card {
    padding: 20px;
    text-align: center;
}

.Emailinput {
    border: none;
    border-radius: 20px;
    width: 60%;
}

.Accordion{
    margin:20px;
}

.getHelpButton{
    padding-top:4px; 
    padding-bottom: 4px; 
    padding-left: 20px; 
    padding-right:20px; 
    background: #B12926; 
    color: #fff; 
    border-radius:20px; 
    margin-left: 20px; 
    float:right
}

.icon {
    background: #FFE7E7;
    border-radius: 50%;
    height: 50px;
    margin-left: 40%;
    width: 20%;
}

.helpCards {
    margin-left: 12.499999995%;
}

.cardHead {
    font-weight: 700;
}

.cardBody {
    font-weight: 300;
}

@media screen and (max-width:990px) {
    .helpCards {
        margin-left: 0;
    }

    .helpContainer {
        width: 100%;
        margin-left: 0;
    }
}