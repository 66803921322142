.header {
    padding: 80px;
    background-color: #FFE7E7;
}

.heading {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
}

.aboutHeading {
    font-size: 50px;
    text-align: center;
    font-weight: 600;
}

.SubHead {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    /* text-align: center; */
}

.data {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #888888;
}

.headingAbout {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.breadcrumb {
    /* font-family: montserrat ; */
    text-align: center;
    background-color: #FFE7E7;
}

.breadcrumbActive {
    color: #B12926;
    /* font-family: montserrat ; */
}

.highlighted {
    font-weight: bold;
    margin-left: 5px;
    color: #B12926;
}

.marginLeft {
    margin-left: 5px;
}

.left {
    text-align: left;
}

.AccordionMargin {
    margin: 20px;

}

.content {
    padding: 40px
}

.contentText {
    /* font-family: montserrat ; */
}



.contentData {
    /* width: 490px; */
    color: #888888;
    font-size: 14px;
    margin-top: 20px;
    line-height: 24px;
}

.Img {
    width: 635px;
    height: 401px;
}

.aboutImg {
    width: 600px;
    height: 320px;
    text-align: center;
}

.heading {
    font-size: 40px;
}

.feedbackImg {
    width: 643.68px;
    height: 395px;
}

.submitButton {
    font-size: 12px;
    height: 50px;
    width: 150px;
    border-radius: 45px;
    background: #B12926;
    float: right;
    color: #fff;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}



/* About store */
.AboutContainer {
    padding: 50px
}

.box {
    /* width: 413px;
    height: 409px; */
    left: 70px;
    top: 5327px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
}

.storeImg {
    height: 202px;
    width: 186px;
    margin-top: 43px;
}

.p15 {
    padding: 15px;
}

.newsImg {
    height: 205px;
    width: 186px;
    margin-top: 43px;
}

.supportImg {
    height: 205px;
    width: 186px;
    margin-top: 40px;
}

.directionText {
    text-decoration: underline;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    /* margin-top: -30px; */
    color: #000000;
}

.breadcrumbHeight {
    height: 260px;
}



@media screen and (max-width:1000px) {
    .Img {
        width: 100%;
        height: auto;
    }

    .contentData {
        width: 100%;
        color: #888888
    }

    .heading {
        font-size: 20px;
    }

    .feedbackImg {
        width: 100%;
        height: auto;
    }

    .box {
        width: 100%;
        height: auto;
        left: 70px;
        top: 5327px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 4px;
        padding: 10px;
        text-align: center;
        margin-top: 10%;
    }

    .aboutImg {
        display: none;
    }

}

@media screen and (max-width:1300px) and (min-width:880px) {

    .Img {
        width: 100%;
        height: auto;
    }

    .aboutImg {
        width: 100%;
        height: auto;
        text-align: center;
    }

    .feedbackImg {
        width: 100%;
        height: auto;
    }

    .contentData {
        width: 300px;
        color: #888888
    }
}

@media screen and (max-width:880px) {

    .AboutContainer {
        padding: 0px;
        padding-bottom: 20px;
    }

    .newsImg {
        height: 100px;
        width: 100px;
        margin-top: 20px;
    }
    
    .storeImg {
        height: 100px;
        width: 100px;
        margin-top: 20px;
    }

    .supportImg {
        height: 100px;
        width: 100px;
        margin-top: 20px;
    }

}