.mainDiv {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 100%;
}

.container {
    /* background:#000;  */
    text-align: center;
    color: #fff;
}

.containerPadding {
    padding: 50px;
    padding-left: 280px;
    padding-right: 280px;
}

.client {
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
}

.storyText {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.clientImage {
    width: 60px;
    height: 60px;
    border-radius: 150px;
    margin-left: 46%;
}

.heading {
    display: flex;
    color: #fff;
    justify-content: center;
}

.marginLeft {
    margin-left: 8px;
    font-weight: bold;
}

.name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.review {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.position {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFAC0D;
}


@media screen and (max-width:880px) {

    .containerPadding {
        padding: 10px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .client {
        font-weight: 400;
        font-size: 20px;
        line-height: 44px;
    }
    .clientImage {
        width: 60px;
        height: 60px;
        border-radius: 150px;
        margin-left: 40%;
    }
    

}