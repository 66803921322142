.container {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
}

.heading{
    margin-top:-12px;
}

.rightpanel {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.title {
    color: #fff;
    width: max-content;

}

.mt{
    margin-top: 5px;
}
.boldName{
    margin-left: 15px; 
    font-weight: bold;
}

.Menu{
    max-width:1500px;
}

.menuItem{
    margin-left: 12px; 
    margin-right: 12px;
    min-width:fit-content;
    cursor: pointer;
    font-size: 18px;
    margin-top: -3px;
    font-weight: 600;
}

.AllCat{
    margin-right: 12px;
    min-width:fit-content;
    cursor: pointer;
    font-size: 18px;
    margin-top: -3px;
    font-weight: 600;
}

.Scroller{
    margin-top:180px; 
    height:50px; 
    width:50px;
}

.ScrollButton {
    width: 54px;
    height: 24px;
    left: 1315px;
    top: 3041px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 26px;
    text-align: center;
    margin-top: 8px;
    margin-left: 10px;
    margin-right: 10px;
}

.content {
    width: 100px;
    height: 100px;
    background-position: center;
    border-radius: 20px;
    flex-shrink: 0;

}

.fontsize {
    font-size: 32px;
}

.contentWrapper {
    display: flex;
    overflow: hidden;
    width: 100%;
    border: 5px solid #ffffff;
    border-radius: 10px;

}

.buttonWrapper {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
}

.button {
    background: #ffffff;
    border: 0;
    color: #000000;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer;
}

.menu {
    display: flex;
    justify-content: flex-end;
}

.boldName {
    font-weight: bold;
    margin-left: 5px;
    font-size: 40px;
}

.flexMargin {
    display: flex;
    margin-left: 10px
}

.LeftArrow{
    margin-top:60px; 
    height:25px; 
    width:25px;
}

.companyImg{
    max-height:200px; 
    max-width:500px; 
    margin-left:40px; 
    margin-right:40px;
}

.end{
    display: flex;
    justify-content: flex-end;
}

.name{
    font-size: 40px;
}

@media screen and (max-width:880px) {
    .fontsize {
        font-size: 20px;
    }

    .menu {
        display: flex;
        justify-content: center
    }
    .Menu{
        max-width:250px;
    }

    .ScrollButton {
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 26px;
        text-align: center;
        margin-top: 8px;
        margin-left: 0px;
        margin-right: -5px;
    }
    .Scroller{
        margin-top:100px; 
        height:20px; 
        width:20px;
    }
    .name{
        font-size: 20px
    }
    .boldName {
        font-weight: bold;
        margin-left: 5px;
        font-size: 20px;
    }
    .menuItem{
        margin-left: 8px; 
        margin-right: 8px;
        min-width:fit-content;
        cursor: pointer;
        font-size: 12px;
        margin-top: 1px;
        font-weight: 600;
    }
    
    .AllCat{
        margin-right: 8px;
        min-width:fit-content;
        cursor: pointer;
        font-size: 12px;
        margin-top: 1px;
        font-weight: 600;
    }
}