.container {
    padding: 20px
}

.discountCard {
    height: 410px;
    width: 302px;
}

.cardPadding {
    padding: 20px;
    color: #fff;
}

.BoldText {
    color: #FFFFFF;
    line-height: 41.45px;
    font-weight: 900;
    font-size: 34px;

}

.mobilePro{
    display: none;
}

.MaxText {
    font-size: 20px;
    line-height: 24.38px;
    font-weight: 500;
}

.discountText {
    color: #FFFFFF;
    line-height: 48.76px;
    font-weight: 700;
    font-size: 40px;
}

.cardinnerCard {
    border: 2px solid white;
    height: 370px;
    text-align: left;
    width: 262px;
}

.cardText {
    margin-top: 60px;
    text-align: left;
    margin-left: 25px;

}



.card {
    border: 1px solid lightgrey;
    width: 220px;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
}

.outerCard {
    padding: 5px;
}

.hot {
    background: #B79D61;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    color: #fff;
}

.discount {
    background: #B12926;
    margin: 5px;
    width: 90px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
}

.innerCard {
    padding: 12px
}

.imgContainer {
    max-width: 180px;
    max-height: 160px;
    margin-top: 8px;
    cursor: pointer;
}


.name {
    text-align: center;
    font-size: 18px;
    line-height: 21.94px;
    font-weight: 500;
    color: #000;
}

.price {
    display: flex;
    margin-top: -8px;
    justify-content: center;
}

.actualPrice {
    color: #BBBBBB;
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
    line-height: 19.07px;
}

.discountPrice {
    color: #B12926;
    font-size: 14px;
    margin-left: 10%;
    font-weight: 400;
    line-height: 19.07px;
}

.img {
    width: 180px;
    height: 160px;
}


.cartButton {
    font-size: 15px;
    border-radius: 167px;
    width: 180px;
    height: 40px;
    background: #B12926;
    color: #fff;
    font-weight: 600;
    line-height: 18.29px;
    border: none;
    margin-top: 15px;
    /* padding:2px; */
}

.arrow {
    margin-left: 20px;
}

.shopButton {
    width: 170px;
    height: 40px;
    left: 108px;
    top: 1814px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    background: #FFFFFF;
    border-radius: 167px;
    border: none;
}



/*Product box*/



@media screen and (max-width:550px) {
    .card {
        background-color:#F3F4F1;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: auto;
        height: auto;
        margin-left: 5px;
        margin-right: 5px;  
    }
    .mobilePro{
        display: contents;
    }
    .imgContainer {
        
        max-width: 180px;
        max-height: 160px;
        margin-top: 8px;
        cursor: pointer;
    }

    .rating{
        display:none
    }
    .desktopPro{
        display: none;
    }

    .mobilePro{
        display: contents;
    }
    .actualPrice{
        color: #979494;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        text-align: left;
        text-decoration: line-through;
        margin-left: 2px;
    }
    .discountPrice{
        color: #000;
        font-size: 14px;
        font-weight: 600;
        font-weight: 700;
        line-height: 18.07px;
        margin-left: 0%;
        text-align: left;
        margin-left: 2px;
    }
    .discountPrice{
        color: #000;
        font-size: 14px;
        font-weight: 600;
        font-weight: 700;
        line-height: 18.07px;
        margin-left: 0%;
        text-align: left;
        margin-left: 2px;
    }
    .mobilePrice{
        color: #000;
        font-size: 10px;
        font-weight: 600;
        font-weight: 700;
        line-height: 18.07px;
        margin-left: 2px;
        text-align: left;
    }
    .price{
        display: none;
    }
    .mobilePrice{
        color: #000;
        font-size: 10px;
        font-weight: 600;
        font-weight: 700;
        line-height: 18.07px;
        margin-left: 2px;
        text-align: left;
    }
    .name{
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        margin-top: 5px;
        text-align: start;
        text-align: left;
        margin-left: 2px;
    }
    .cartButton {
        text-align: center;
        width: 100%;
        height: 20px;
        margin-top: 5%;
        font-size: 12px;
        padding:1px
    }
    .img {
        margin-top: 5px;
        width: auto;
        height: auto;
        min-height: 80px;
        max-height: 80px; 
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        max-width: 50px;
    }
    .mobileHidden{
        display: none;
    }
    .innerCard{
        padding: 0px;
    }
}

@media screen and (max-width:1400px) and (min-width:990px) {
    .discountCard {
        height: auto;
        width: auto;
        max-width: 210px;
    }
    .cardinnerCard {
        border: 2px solid white;
        height: 370px;
        text-align: left;
        width: 175px;
    }
    
}

@media screen and (max-width:990px) {
    .discountCard {
        height: auto;
        width: auto;
    }
    .cardinnerCard {
        border: 2px solid white;
        height: 370px;
        text-align: left;
        width: auto;
    }    
    .productBox {
        margin-top:30px;
        margin-left: 0px;
    }

    .container {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px
    }
}


@media screen and (max-width:550px) {
    .card {
        background-color:#F3F4F1;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: 140px;
        height: auto;
        margin-left: 5px;
        margin-right: 5px;  
        margin-bottom: 5px;
    }  
    .img {
        margin-top: 5px;
        width: auto;
        height: auto;
        min-height: 80px;
        max-height: 80px; 
        margin-left: 10px;
        margin-right: 5px;
        margin-bottom: 5px;
        max-width: 120px;
    } 
}