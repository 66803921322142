.card {
    /* border:1px solid lightgrey; */
    border: 1px solid #EEEEEE;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 302px;
    height: 410px;
}

.outerCard {
    padding: 7px;
}

.mt20 {
    margin-top: -20px;
}

.hot {
    background: #B79D61;
    /* margin:5px;
    padding-left:15px;
    padding-right:15px; */
    width: 40px;
    height: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
}

.discount {
    background: #B12926;
    text-align: center;
    width: 90px;
    height: 20px;
    border-radius: 17px;
    color: #fff;
    margin-left: 5px;
}

.innerCard {
    padding: 10px
}

.imgContainer {
    /* border:1px solid lightgrey;  */
    height: 218px;
    width: 262px;
    margin-top: 10px;
}

.img {
    height: 218px;
    width: 262px;
}

.name {
    text-align: center;
    font-size: 18px;
    line-height: 21.94px;
    font-weight: 500;
    color: #000;
}

.price {
    display: flex;
    margin-top: -8px;
    justify-content: center;
}

.mobilePro {
    display: none;
}

.mobileHidden {
    display: contents;
}

.actualPrice {
    color: #BBBBBB;
    font-size: 18px;
    font-weight: 700;
    text-decoration: line-through;
    line-height: 19.07px;

}

.discountPrice {
    color: #B12926;
    font-size: 18px;
    margin-left: 10%;
    font-weight: 700;
    line-height: 19.07px;
}

.cartButton {
    font-size: 15px;
    border-radius: 167px;
    width: 100%;
    height: 40px;
    background: #B12926;
    color: #fff;
    font-weight: 600;
    line-height: 18.29px;
    border: none;
    margin-top: 15px;
    /* padding:2px; */
}




/* Round Product Box */

.roundouterBox {
    border-radius: 130px;
    border: 1px solid lightgrey;
    width: 190px;
    height: 190px;
}

.colorborder {
    border-radius: 130px;
    border: 1px solid #B12926;
    width: 190px;
    height: 190px;
}

.center {
    text-align: center;
    margin-top: 50%;
}

.innerBox {
    border-radius: 130px;
    /* border:1px solid lightgrey;   */
    width: 170px;
    height: 170px;
    margin-left: 10px;
    margin-top: 10px;
    /* margin-top:10px;
    margin-left:10px;
    background:lightgrey; */
}

.roundImg {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-top: 4px;
    margin-left: 3.5px;
}

.centerButton {
    width: 74px;
    height: 34px;
    left: 128px;
    top: 828px;
    margin-top: 40%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    border: none;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
}

.button {
    width: 125px;
    height: 39px;
    left: 340px;
    top: 917px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    border: none;
    /* margin-top: 90%; */
    color: #000;
    font-size: 14px;
    font-weight: 800;
    line-height: 11px;
}

@media screen and (max-width:880px) {


    .roundouterBox {
        border-radius: 50%;
        border: 1px solid lightgrey;
        width: 70px;
        height: 70px;
        margin-top: 15px;
    }

    .imgContainer {
        height: auto;
        width: auto;
        margin-top: 10px;
    }

    .card {
        border: 1px solid #EEEEEE;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: auto;
        height: auto;
        margin-left: 5px;
        margin-right: 5px;
    }

    .innerBox {
        border-radius: 50%;
        border: 1px solid lightgrey;
        width: 52px;
        height: 52px;
        margin-top: 10px;
        margin-left: 8px;
        background: lightgrey;
    }

    .roundImg {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        margin-top: 4px;
        margin-left: 3.5px;
    }

    .button {
        width: auto;
        height: auto;
        /* left: 340px; */
        /* padding: 5px; */
        /* padding-left: 20px;
        padding-right: 20px; */
        top: 917px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 40px;
        border: none;
        margin-top: 10%;
        color: #000;
        font-size: 10px;
        font-weight: 800;
        /* line-height: 17.07px; */
        /* max-width: 50px; */
        max-height: 100%;
    }

}

@media screen and (min-width:550px) and (max-width:1400px) {
    .card {

        border: 1px solid #EEEEEE;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: auto;
        height: auto;
        margin-left: 5px;
        margin-right: 5px;
        max-width: 220px;
    }

    .imgContainer {
        /* border:1px solid lightgrey;  */
        height: 218px;
        width: 262px;
        margin-top: 10px;
    }

    .img {
        margin-top: 10px;
        width: 90%;
        height: 90%;
        cursor: pointer;
        max-width: 180px;
        max-height: 180px;
        min-height: 150px;
        margin-bottom: 10px;
    }


}

@media screen and (max-width:550px) {
    .card {
        background-color: #F3F4F1;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: 140px;
        height: auto;
        margin-left: 5px;
        margin-right: 5px;
    }

    .mobilePro {
        display: contents;
    }

    .rating {
        display: none
    }

    .desktopPro {
        display: none;
    }

    .mobilePro {
        display: contents;
    }

    .actualPrice {
        color: #979494;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        text-align: left;
        text-decoration: line-through;
        margin-left: 2px;
    }

    .discountPrice {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        font-weight: 700;
        line-height: 18.07px;
        margin-left: 0%;
        text-align: left;
        margin-left: 2px;
    }

    .discountPrice {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        font-weight: 700;
        line-height: 18.07px;
        margin-left: 0%;
        text-align: left;
        margin-left: 2px;
    }

    .mobilePrice {
        color: #000;
        font-size: 10px;
        font-weight: 600;
        font-weight: 700;
        line-height: 18.07px;
        margin-left: 2px;
        text-align: left;
    }

    .price {
        display: none;
    }

    .mobilePrice {
        color: #000;
        font-size: 10px;
        font-weight: 600;
        font-weight: 700;
        line-height: 18.07px;
        margin-left: 2px;
        text-align: left;
    }

    .name {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        margin-top: 5px;
        text-align: start;
        text-align: left;
        margin-left: 2px;
    }

    .cartButton {
        text-align: center;
        width: 100%;
        height: 20px;
        margin-top: 5%;
        font-size: 12px;
        padding: 1px
    }

    .img {
        margin-top: 5px;
        width: auto;
        height: auto;
        min-height: 80px;
        max-height: 80px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        max-width: 120px;
    }

    .mobileHidden {
        display: none;
    }

    .innerCard {
        padding: 0px;
    }
}