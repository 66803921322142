
/* Terms and condition */
.padding30{
    padding:30px;
}

.heading{
    font-weight:700;
    text-align:center;
    margin-top:50px;
}

.list{
    font-weight:400; 
    line-height:24px
}

/* privacy policy */



.div {
    padding:20px
}

.contentDiv{
    margin-top: 20px;
    margin-bottom: 20px;
}

.heading {
    font-weight:600;
    
}

.details {
    color:#555555;
    font-weight:400;
    margin-bottom:15px;
    margin-top:15px;
}

.border {
    border-top: 1px solid lightgray;
}

.info {
    border-color: #B12926;
    color:#000;
    background-color: #fff;
    padding:10px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    border-radius: 20px;
}

.circleImgCont{
    margin-left: 10px; 
    margin-right: 10px;
    margin-bottom: 12px; 
    margin-top: 5px;
}

.li{
    font-size: 13px;
}

.content {
    margin-top:50px;
    margin-bottom: 50px;
}

.scrollDiv{
    display:flex;
    flex-direction:row; 
    overflow-x:scroll; 
    overflow-y:hidden;
    height: 250px;
    
}

.productDiv{
    margin-top: 30px;
    margin-bottom: 30px;
}