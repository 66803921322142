.container {
    padding: 20px;
    font-family: montserrat;
}

.card {
    padding: 20px;
    width: 500px;
    /* height: 522px; */
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}

.center {
    text-align: center;
    justify-content: center;
}

.socialText {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: 15px;
    margin-top: 5px;

    color: #FFFFFF;
}

.social {
    width: 210px;
    height: 48px;
    left: 500px;
    top: 366px;

    background: #5890FF;
    border-radius: 50px;
}

.icon {
    margin-left: 10px;
}

/* .googleButton {
    background: #EA4335;
    width: 100%;
    margin: 10px;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    padding: 8px;
    border: none;

} */

.googleText{
    margin-top:15px
}

.GoogleIconCont{
    margin-top:15px;
    margin-right: 10px;
}

.smal {
    display: flex;
    flex-direction: row;
}

.smal:before,
smal:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid lightgray;
    margin: auto;
}

.smal:before {
    margin-right: 10px
}

.smal:after {
    margin-left: 10px
}

.small {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    margin-top: 8px;

    color: #888888;
}

.input {
    display: block;
    min-width: 100%;
    /* margin-top: 1em; */
    margin-bottom: 1em;
    padding: 1em;
    /* width: 35em; */
    border-radius: 8px;
    height: 48px;
    border-style: none;
    font-family: montserrat;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
}

.LoginInput{
    display: block;
    min-width: 50%;
    width: 100%;
    /* margin-top: 1em; */
    margin-bottom: 1em;
    padding: 1em;
    /* width: 35em; */
    border-radius: 8px;
    height: 46px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;    
}

.PassInput{
    display: block;
    min-width: 50%;
    width: 100%;
    /* margin-top: 1em; */
    margin-bottom: 1em;
    padding: 1em;
    /* width: 35em; */
    border-radius: 8px;
    height: 46px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;    
    border: none;
    font-size: 18px;
}

.LoginInputCont{
    display: flex;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;    
}

.forgotPass {
    float: right;
    color: #C1C1C1
}

.socialButton {
    border: none;
    background: #1976D2;
    width: 100%;
    margin: 10px;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    /* padding:8px; */
}


.button {
    height: 50px;
    left: 500px;
    top: 641px;
    width: 100%;
    background: #B12926;
    border-radius: 164px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: montserrat;
    color: #FFFFFF;
}

.member {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #888888
}

.GoogleIcon{
    height: 20px;
    width: 20px;
}
.border{
    border-top:1px solid lightgray;
    margin-top: 10px;
  }
.account {
    color: #2875DE;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
}

.loginText {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
    font-family:sans-serif;
    margin-top: 10px;
}

.googleButton{
    height: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width:990px) {
    .card {
        padding: 20px;
        width: auto;
        /* height: 522px; */
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }
    
}