.container {
    padding: 20px;
}

.border {
    border-top: 1px solid lightgray;
    margin-top: 20px;
}

.red {
    color: #B12926
}

.prodSmall {
    margin-right: 10px;
    border: 1px solid lightgray;
    padding: 10px
}

.prodSmallImg {
    height: 100px;
    width: 100px;
}

.counter {
    justify-content: space-between;
    border: 1px solid lightgray;
    width: 25%;
    border-radius: 10px;
    margin: 2px;
}

.marginTop10 {
    margin-top: 10px;
}

.prize {
    font-size: 18px;
    font-weight: 600;
    color: green;
    margin-left: 10px;
}

.disPrize {
    font-size: 18px;
    font-weight: 600;
    color: black;
    margin-left: 10px;
    text-decoration: line-through;
}

.ImgCon {
    height: 510px;
    width: 535px;
    border: 1px solid lightgray;
}

.mainImg {
    height: 510px;
    width: 535px;
    padding: 20px;
}

.counterFont {
    font-size: 24px;
    font-weight: 400;
}

.margin5 {
    padding: 5px;
}

.wishCont {
    border: 1px solid lightgray;
    border-radius: 50%;
    margin-top: 10px;
}

.wishIcon {
    padding: 10px;
}

.marginLeft {
    margin-left: 20px;
}

.dropdown {
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px
}

.counterNum {
    font-size: 20px;
    margin-top: 3px;
}

.dropdownItem {
    padding: 10px;
    color: #000;
    justify-content: space-between
}

.dropdownBorder {
    border-bottom: 1px solid lightgray;
    margin-top: 2px;
    margin-bottom: 2px;
}

.innerCard {
    padding: 20px;
    color: #fff;
}

.cardContent {
    border: 1px solid white;
    height: 360px;
    text-align: left;
}

.CartBtn {
    background: #B12926;
    margin: 5px;
    padding-left: 15px;
    font-weight: 600;
    padding-right: 15px;
    border-radius: 20px;
    color: #fff;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
}

.ml5 {
    margin-left: 5px;
}

.cod {
    font-weight: 800;
}

.buyBtn {
    background-color: #337AB7;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 600;
    border-radius: 20px;
    color: #fff;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
}

.codImg {
    height: 20px;
    width: 20px;
}

.ReplImg {
    height: 35px;
    width: 35px;
}

.cardMargin {
    margin-top: 20px;
    margin-left: 5px;
}

.discountBanner {
    height: 410px;
    width: 306px;
}

.saperation {
    border-left: 2px solid lightgray;
    margin-left: 20px;
    margin-right: 20px;
    height: 80%
}


.Tabs {
    margin-top: 50px;
    border: 1px solid lightgray;
}

.TabContent {
    padding: 20px;
}

.shopButton {
    background-color: #fff;
    border-radius: 20px;
    font-size: 14px;
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: #000;
    border: none;
}

.featureImg {
    width: 87px;
    height: 77px;
}

.FeatureBox {
    border: 1px solid lightgray;
    padding: 5px;
    width: 80%;
    margin-top: 20px;
}

.AddText {
    margin-top: 5px;
}

.iconBorder {
    border: 1px solid lightgray;
    border-radius: 50%;
}

.borderBottom {
    border-bottom: 1px solid lightgray;
}

.labelFont {
    font-size: 20px;
}

.hot {
    background: #B79D61;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    color: #fff;
}

.discount {
    background: #B12926;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    color: #fff;
    margin-left: 20px;
}

.border {
    border: 1px solid lightgray
}

.borderTop {
    border-top: 1px solid lightgray
}

@media screen and (max-width:550px) {
    .ImgCon {
        height: 100%;
        width: 100%;
        min-height: 40px;
        min-width: 40px;

        border: 1px solid lightgray;
    }


    .mainImg {
        height: 100%;
        width: 100%;
        padding: 20px;
    }
}

@media screen and (min-width:650px) and (max-width:1500px) {
    .ImgCon {
        height: 100%;
        width: 100%;
        min-height: 40px;
        min-width: 40px;
        border: 1px solid lightgray;
    }


    .mainImg {
        height: 100%;
        width: 100%;
        padding: 20px;
    }


}

@media screen and (max-width:980px) {
    .borderTop {
        border-top: none
    }

}