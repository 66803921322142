.padding {
    padding: 20px;
}

.container{
    margin-top: 20px;
    margin-bottom: 20px;
}

.redBorder{
    border-left: 3px solid #B12926;
    list-style: none;
}

.LeftPanel{
    margin-top: 20px;
}

.borderBottom {
    border-bottom: 1px solid lightgrey;
        margin-top: 20px; 
        margin-bottom: 20px;
}


.recentPostName{
    margin: 8px;
    margin-left: 20px;
    margin-top: 0px;
}

.marginTopBottom {
    margin-top: 20px;
    margin-bottom: 20px;
}

.button {
    border: none;
    color: #fff;
    background-color: #B12926;
    border-radius: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    /* margin-top: 10px; */
}

.marginTop {
    margin-top: 20px;
}

.recentPostImages {
    height:60px;
    width:60px;
}

.tagButton {
    margin:10px;
    margin-left: 0px;
    border:none;
    padding: 15px;
    background-color: #000;
    color:#fff
}

.name {
    color: #b12926;
}

.subHeadings {
    font-size: 16px;
    font-weight: 600;
    color:#555555
}

.detailImage {
    height: 512px;
    width: 945px;
}

.blogBlock {
    color:#B12926;
    border-left:'5px solid red'
}

.margin20{
    margin:20px;
}

.commentBlock{
    margin: 20px; 
    padding: 30px
}

.center{
    text-align: center;
    justify-content: center;
}

.blogGreyBlock{
    background-color: #EEEEEE;
}

.hashButtons{
    margin:10px;
    margin-left: 0px;
    border:none;
    padding: 15px;
    background-color: #B12926;
    color:#fff    
}

.marginLeft{
    margin-left: 50px;
}

.img{
    width:100%;
    height:auto;
}

@media screen and (max-width:1300px) {
    
.detailImage {
    width: 100%;
    height: auto;
}    
}

@media screen and (max-width:400px) {
    .marginLeft{
        margin-left: 0;
    }        
}