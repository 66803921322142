.container {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
}

.rightpanel {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.righttoppanel {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
}


.title {
    color: #fff;
    width: max-content;

}

.ScrollButton {
    width: 54px;
    height: 24px;
    left: 1315px;
    top: 3041px;

    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 26px;
    text-align: center;
}

.content {
    width: 100px;
    height: 100px;
    background-position: center;
    border-radius: 20px;
    flex-shrink: 0;

}

.fontsize {
    font-size: 40px;
}

.contentWrapper {
    display: flex;
    overflow: hidden;
    width: 100%;
    border: 5px solid #ffffff;
    border-radius: 10px;

}

.buttonWrapper {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
}

.button {
    background: #ffffff;
    border: 0;
    color: #000000;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer;
}

.menu {
    display: flex;
    justify-content: flex-end
}

.boldName {
    font-weight: bold;
    margin-left: 5px;
}

.flexMargin {
    display: flex;
    margin-left: 10px
}

@media screen and (max-width:880px) {
    .fontsize {
        font-size: 20px;
    }

    .menu {
        display: flex;
        justify-content: center
    }

    .righttoppanel {
        margin-top: 0px;
        display: flex;
        justify-content:space-between;
    }

    .rightpanel {
        margin-top: 0px;
        display: flex;
        justify-content:space-between;
    }

    .container {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 10px;
    }
    .ScrollButton {
        width: 100%;
        height: 24px;
        left: 1315px;
        top: 3041px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 26px;
        text-align: center;
    }
    
}