.container {
    border:1px solid lightgray;
    margin-top: 20px;
    margin-bottom: 50px;
}

.header {
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
}

.heading {
    margin: 20px;
    font-size: 30px;
   
}

.dateTime{
    margin-top:10%; 
    color: #888888;
}

.order{
    margin-top:10%; 
    color: #3399CC;
}

.invoice{
    margin-top:5%; 
    color: #3399CC;
}

.bill{
    margin-top:10%; 
    color: #888888;
}

.padding20{
    padding:20px
}

.headingText{
    font-size: 18px;
    color:#888888
}

.text{
    justify-content: flex-end;
}

input{
    font-size: 15px;
    width: 100%;
}

.button{
    background-color: pink;
    color: #B12926;
    border: none;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.update{
    margin-top: 20px;
    text-align:end;
    margin-bottom: 20px;
}

.margin{
    margin-top: 20px;
}

.profileButton{
    background-color: #9F7D2D;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;    
}