.container {
    border:1px solid lightgray;
    margin-top: 20px;
    margin-bottom: 50px;
}

.header {
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
}

.heading {
    margin: 20px;
    font-size: 30px;
}

.text{
    justify-content: flex-end;
}

input{
    font-size: 15px;
    width: 100%;
}

.button{
    background-color: pink;
    color: #B12926;
    border: none;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.img{
    height:95px;
    width:115px;
}

.update{
    margin-top: 20px;
    text-align:end;
    margin-bottom: 20px;
}

.margin{
    margin-top: 20px;
}

.profileButton{
    background-color: #9F7D2D;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;    
}

.pending{
    color:#E50202;
    background-color: pink;
    padding: 10px;
    width:fit-content;
    font-size: 12px;
    font-weight: 600;
    border-radius: 20px;
}

.done{
    color:#1CA301;
    background-color: lightgreen;
    padding: 10px;
    width:fit-content;
    font-size: 12px;
    font-weight: 600;
    border-radius: 20px;
}


/* Account Enquiry */
.headerBottom{
    margin-bottom:30px;
}

.tableDiv {
    margin-top:30px; 
    padding:20px;
}

.tableHeaderStyle{
    background:#FBFBFB; 
    color:#666666;
}

.tableBodyStyle{
    color:#888888;
}

.productName{
    font-size:18px; 
    margin-top:10%;
}

.specialText{
    color:green; 
    margin-top:-10%;
}

.dateTime{
    margin-top:20%;
}

.orderId{
    margin-top:20%; 
    color:#2875DE;
}

.category{
    margin-top: 10%;
}

/* Faqs */

.center{
    justify-content: center;
    text-align: center;
}

.data{
    margin-top: 30px; 
    margin-bottom: 30px;
    padding: 30px;
}

/* order */

.checkboxMargin{
    margin-top: 70%;
}

.dollar{
    margin-top: 13px ;
}

.price{
    color: green; 
    font-size: 25px; 
    font-weight: bold;
}

@media screen and (max-width:767px) {
    .img{
        width: 100%;
        height:auto;
    }
    
}


@media screen and (max-width:440px) {
    .img{
        width: 80px;
        height:75px;
    }
    
}