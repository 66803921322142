.container {
    padding:40px
}

.border {
    border: 1px solid lightgray;
}

.navbarMargin {
    margin-right: 0.7rem;
}

.list {
    padding:10px;
    border-bottom:1px solid lightgray;
}

.active {
    background-color: #FFE7E7;
    color: #B12926;
}

.barHeading {
    margin:2px; 
    margin-left:10px;
}

.margin{
    margin-top: 0px;
}

.tabName{
    display:flex; 
    font-size: 12px; 
    border-bottom: 1px solid lightgray; 
    justify-content:flex-start; 
    min-height: 0; 
    padding: 10px;
}

@media screen and (max-width:990px) {
    .margin{
        margin-top: 20px;
    }
    
}