.container {
    border:1px solid lightgray;
}

.wishCon{
    border:1px solid lightgray;
    margin-top: 20px;
    margin-bottom: 50px;
}

.wishIcon{
    font-size: 20px;
    background: #fff; 
    border-radius: 50%; 
    color: #000;
}

.wishBtn{
    background-color: #B12926;
    border-radius: 20px; 
    font-size: 12px; 
    padding-left: 20px
}

.header {
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
}

.heading {
    margin-left: 20px;
}

.padding{
    padding:20px;
}

.innercontainer{
    border:1px solid lightgray;
}

.textSecondary{
    color:#888888
}

.column{
    display:flex;
    flex-direction:column;
    margin-top: 5px;
}

.textRed{
    color: #B12926;
}

.small {
    font-size: 11px;
    font-weight: bold;
}

.bold{
    font-weight: bold;
}

.image{
    width: 60px;
    height: 60px;
}

.right{
    float: right;
}

.codeBox {
    display: flex;
    border: 1px solid lightgray; 
    padding:2px;
    border-radius:20px;
    justify-content:space-between; 
    /* margin-top:30px */
}

.inputStyle {
    border:none
}

.margin{
    margin:10px;
}

.copy {
    margin-top: 10px;
    margin-bottom: 10px;
}

.copyBotton {
    background:#9F7D2D;
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius:20px;
    float:right;
    color:#fff;
}
