.header {
    padding: 80px;
    background-color: #FFE7E7;
}

.heading {
    font-size: 34px;
    font-weight: 700;
    font-family: montserrat;
    text-align: center;
}

.contactBox {
    border: 2px solid #888888;
    border-radius: 5px;
    padding: 10px;
}

.locationIcon {
   font-size: 25px;
    margin-right: 10px;
    margin-top: 5px;
    color: #2196F3;
}

.border{
    border-top: 2px solid lightgray;
    margin-top: 10px;
}

.boldT {
    color: #000;
    font-weight: bold;
}

.normal {
    color: #000;
    font-weight: 500;
}

.callIcon {
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.whatsAppIcon {
    color: green;
    font-size: 20px;
    margin-right: 10px;
}

.MailIcon {
    color: #B12926;
    font-size: 20px;
    margin-right: 10px;
}

.Name {
    font-size: 20px;
}

.breadcrumb {
    font-family: montserrat;
    text-align: center;
    background-color: #FFE7E7;
}

.breadcrumbActive {
    color: #B12926;
    font-family: montserrat;
}

.content {
    padding: 20px;
}

.contentHeading {
    font-size: 34px;
    font-weight: 700;
    font-family: montserrat;
    padding: 15px;
    text-align: left;
}

.question {
    font-size: 20px;
    font-weight: 600;
    font-family: montserrat;
    color: #666666;
}

.answer {
    font-size: 14px;
    font-weight: 500;
    font-family: montserrat;
    color: #888888;
}

.contactForm {
    width: 540px;
    height: 541px;
    background: #F9F9F9;
    border-radius: 4px;
}

.form {
    padding: 10px
}

.input {
    display: block;
    min-width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 1em;
    /* width: 35em; */
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
}

.button {
    font-size: 16px;
    border: none;
    padding: 5px;
    background: #B12926;
    color: #fff;
    font-size: 16px;
    width: 100%;
    height: 50px;
    border-radius: 164px;
    margin-top: 15px;
}

.map {
    width: 1480px;
    height: 448px;
    margin-top: 20px;
    margin-bottom: 20px;
}



@media screen and (max-width:880px) {

    .contactForm {
        width: 100%;
        height: auto;
        background: #F9F9F9;
        border-radius: 4px;
    }

    .map {
        width: 100%;
        height: auto;
    }

}

@media screen and (max-width:1450px) and (min-width:880px) {

    .map {
        width: 100%;
        height: auto;
    }

}