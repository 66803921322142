.container {
    background-repeat: round;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    /* min-height: 500px; */
}

.izaharText {
    text-align: left;
    color: #fff;
    font-size: 50px;
    line-height: 60.95px;
    font-weight: 300;
}

.newSubHeading {
    width: 70%;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 85px;
    text-align: left;
    color: #FFFFFF;

}

.Head {
    text-align: left;
    color: #fff;
    font-size: 40px;
    line-height: 60.95px;
    font-weight: 300;
}

.newHeading {
    width: 50%;
    /* height: 61px; */
    /* left: 65px; */
    top: 302px;
    color: #fff;
    font-style: normal;
    font-weight: 300;
    font-size: 45px;
    line-height: 61px;

    text-align: left;
}

.perfumerText {
    font-weight: 700;
    text-align: left;
    color: #fff;
    font-size: 70px;
    line-height: 85.33px;
}

.subText {

    font-weight: 700;
    text-align: left;
    color: #fff;
    font-size: 30px;
    line-height: 85.33px;

}

.bannerNewDetails {
    width: 590px;
    height: 50px;
    left: 71px;
    top: 448px;
    text-align: left;
    /* font-family: 'Open Sans'; */
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    color: #FFFFFF;
}


.detail {
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-style: Sans;
    line-height: 24.51px;
    width: 50%;
}

.bannerDetail {
    text-align: left;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-style: Sans;
    line-height: 22px;
    margin-top: 10px;
}

.button {
    background-color: #9F7D2D;
    border-radius: 190px;
    margin-top: 20px;
    font-size: 15px;
    padding: 10px;
    width: 174px;
    height: 46px;
    color: #fff;
    border: none;
    width: 174px;
    font-weight: 600;
    text-align: left;
}

.arrow {
    margin-left: 30px;
    margin-top: 0px;
}

.a {
    color: #fff;
}


/* banner 2 */
.bannerContainer {
    padding-top: 120px;
    padding-left: 50px;
    height: 448px;
    background-repeat: round;
    /* background-color:#000; */
}

.bannerCont {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 65px;
}

.textNew {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 85px;
    color: #FFFFFF;
    text-align: left;
    width: 50%;
}

.textNewHead {
    width: 50%;
    /* height: 61px; */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 61px;
    color: #FFFFFF;
    text-align: left;
}

.mensPerfume {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
    width: 60%;
}

@media screen and (max-width: 999px) {
    .container {
        padding-top: 5px;
        padding-bottom: 50px;
        padding-left: 0px;
        height: auto;
        /* margin-top: 60px; */
    }
    .bannerCont {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    

    .izaharText {
        font-size: 15px;
    }

    .perfumerText {
        font-size: 20px;
    }

    .detail {
        font-size: 13px;
    }

    .mensPerfume {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        width: 100%;
        margin-top: 10px;
    }

    .bannerNewDetails {
        text-align: center;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        font-style: Sans;
        line-height: 22px;
        width: 100%;
        margin-top: 10px;
    }
}

@media screen and (max-width:990px) and (min-width:880px) {

    .container {

        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 20px;
        /* margin-top: 50px; */
    }
}

@media screen and (max-width:990px) {
    .bannerContainer {
        padding: 10px;
        /* background-color: #000; */
        height: auto;
    }

    .textNewHead {
        width: 100%;
        color: #fff;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px;
        padding-top: 20px;
        text-align: center;
    }

    .textNew {
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;

    }

    .bannerDetail {
        text-align: center;
        color: #fff;
        font-size: 13px;
        font-weight: 400;
        font-style: Sans;
        line-height: 20px;
        width: 100%;
    }

    .container {
   padding: 5px;
        height: auto;
        /* margin-top: 60px; */
    }

    .button {
        background-color: #9F7D2D;
        border-radius: 20px;
        height: auto;
        width: auto;
        font-size: 12px;
        padding: 10px;
        padding-left: 10px;
        padding-right: 10px;
        color: #fff;
        border: none;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}