.container {
    padding: 20px;
}

.img {
    height: 60px;
    width: 60px;
}

.counter {
    justify-content: space-between;
    border: 1px solid lightgray;
    width: 80%;
    border-radius: 10px;
    margin-top: 15%;
}

.underline {
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}

.plr{
    padding-left: 25px;
    padding-right: 25px;
}

.mt20{
   margin-top: 20%; 
}

.font {
    font-size: 15px;
    font-weight: 600;
}

.cartTotal {
    background-color: #F9F9F9;
    border: 1px solid lightgray;
    width: 80%;
    float: right;
}

.productName {
    font-size: 14px;
    font-weight: bold;
    margin-top: 3%;
    margin-left: 10px;
}

.quantity {
    margin-top: 20%;
    font-weight: bold;
}

.marginTop {
    margin-top: 10%;
}

.price {
    margin-top: 13px;
    font-weight: bold;
}

.priceCheck{
    margin-top: 25px;
    font-weight: bold;
}

.addrsCont{
    padding: 20px; 
    border: 1px solid lightgray; 
    border-radius: 8px;
}

.width90 {
    width: 90%;
}

.border {
    border-bottom: 1px solid lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkoutButton {
    width: 100%;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    background-color: #B12926;
    color: #fff;
    padding: 5px;
    border: none;
    border-radius: 148px;
    margin-top: 20px;
}

.NoData{
    margin-top: 50px;
    font-size: 48px;
    text-align: center;
    margin-bottom: 50px;
}

.Apply {
    width: 100%;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    background-color: #B12926;
    color: #fff;
    /* padding: 5px; */
    border: none;
    border-radius: 10px;
}

.inputPromo {
    display: block;
    width: 100%;
    /* margin-top: 1em; */
    /* margin-bottom: 1em; */
    /* padding: 1em; */
    height: 40px;
    /* width: 35em; */
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
}

.checkoutWhite {
    width: 80%;
    background-color: #ffffff;
    color: #888888;
    padding: 5px;
    border: 1px solid #DADADA;
    border-radius: 20px;
    float: right;
    margin-top: 30px;
    font-weight: 900;
}

.black {
    color: #000;
}

.input {
    display: block;
    margin-top: 1em;
    width: 320px;
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
}

.questionIcon {
    margin-top: 10px;
    margin-left: 10px;
}

.addressContainer {
    border: 1px solid lightgray;
}

.header {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width:1500px) {
    .input {
        display: block;
        margin-top: 1em;
        width: 100%;
        border-radius: 8px;
        border-style: none;
        border: 1px solid #e4e6e8;
        transition: 0.1s ease;
    }
}

@media screen and (max-width:998px) {
    .cartTotal {
        background-color: #F9F9F9;
        border: 1px solid lightgray;
        width: 100%;
        float: center;
    }
}