.container {
    padding: 30px;
}

.categoryFrame {
    border: 1px solid lightgray;
    padding: 20px;
    padding-top: 0px;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
    height: auto;
    color:#888888;
}

.border {
    border-bottom: 1px solid lightgray;
    margin-top: 1px;
    margin-bottom: 10px;
}

.categHeading{
    font-weight: 600;
    font-size: 30px;
    margin-top: 5px;
    color:#504e4e
}

.flexWrap{
    display: flex;
    flex-wrap: wrap;
}

.accordianOuter {
    margin: 0px;
    width: 100%;
}

.height {
    min-height: 100%;
}

.margin0 {
    margin: 0px;
}

.margin0:hover{
    box-shadow: 0px 8px 14px -4px rgba(0,0,0,0.2);
    transform: translate(0px, -3px);
}

.right{
    justify-content: flex-end;
}

.priceFrame {
    border: 2px solid lightgray;
    width: fit-content;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin: 5px;
    font-size: 12px;
    border-radius: 5px;
    color:#888888;
    font-weight: 600;
}

.priceFrame:hover{
    box-shadow: 0px 8px 14px -4px rgba(0,0,0,0.2);
      transform: translate(0px, -3px);
}

.priceFrameAfter{
    border: 1px solid #000;
    width: fit-content;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin: 5px;
    font-size: 12px;
    border-radius: 5px;
    color:#000;
    font-weight: 600;
}

.marginLeft {
    margin-left: 10px;
    /* margin-top: -15px */
}

.marginLeftRight {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 8px;
}

.dropdownPadding {
    padding: 10px;
}

.autoWidth{
    width:auto;
    margin-top: 205px;
}

.marginTopBottom {
    margin-top: 20px;
    margin-bottom: 20px;
}

.marginTopBottom10{
    margin-top: 10px;
    margin-bottom: 10px;
}

.bar {
    background: lightgray;
    display: flex;
    justify-content: space-between;
}

.Pangination {
    background: #888888;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 5px;
}

@media screen and (max-width:880px) {
    .marginLeftRight {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 8px;
    }
}