.nopadding {
  padding: 0;
}

.padding20 {
  padding: 20px
}

.textSecondary {
  color: lightgray
}

.mobile-hidden{
  display: block;
}

.p100{
  padding: 100px;
}

.p10{
  padding:10px
}

.anchor{
  color: #FBFBFB;
}

.image-test{
  height: 50px;
  width: 50px;
}

.cancel-cont{
  padding: 10px; 
  padding-top: 50px; 
  padding-bottom: 50px
}

.badge-style{
  text-align:center; 
  margin-top:0px;
}

.verify-email-text{
  font-family: montserrat;
  font-size: 15px;
  font-weight: 700;
}

.verify-success-text{
  font-family: montserrat;
  font-size: 24px;
  font-weight: 700;
}

.verify-link-text{
  font-family: montserrat;
  font-size: 24px;
  font-weight: 700;
  text-decoration: underline;
}

.ptb-100{
  padding-top: 150px;
  padding-bottom: 150px;
}

.font24{
  font-size: 24px;
}

.cancel-order{
  font-size: 24px;
  margin-top: -5px;
}

.italic{
  font-style: italic;
  font-size: 14px;
}

.border-top{
  border-top: 1px solid lightgray;
}

.track-modal{
  width: 70%;
}

.font18{
  font-size: 18px;
}

.review-modal{
  width:40%
}

.product-review{
  margin-left: 20px;
  margin-top: -10px;
}

.breadcrumb {
  background: #FFE7E7;
  font-size: 15px;
}

.ml-15{
  margin-left: 15px;
}

.p-3{
  padding: 30px;
}

.text-link{
  color:#3399CC
}

.max-content{
  width: max-content;
}

.order-padding{
  padding:25px
}

.mb-2{
  margin-bottom: 20px;
}

.show-more{
  color: #000;
  border: none;
  font-weight: 600;
  margin-left: 10px;
  /* display: flex; */
}

.featured-heading{
  font-weight: 600;
  font-size: 24px;
  margin-top: 5px;
  color:#504e4e
}

.font-18{
  font-size: 18px;
}

.font-15{
  font-size: 15px;
}

.font-13{
  font-size: 13px;
}

.font-14{
  font-size: 14px;
}


.font-12{
  font-size: 12px;
}

.cart-summary{
  font-weight: 600;
  font-size: 30px;
  margin-top: 5px;
  color:#504e4e
}

.swal2-popup {
  font-size: 1.8rem !important;
  font-family: montserrat !important;
  font-weight: 600 !important;
  max-width: 400px !important;
  max-height: 400px !important;
}

.sidebar-links {
  display: flex; 
  font-size: 12px; 
  border-bottom: 1px solid lightgray; 
  justify-content: flex-start;
  min-height: 0px; 
  padding: 10px;
}

.border-bottom{
  border-bottom: 1px solid lightgray;
}
.order-detail-border{
  border: 1px solid lightgray;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}


.star-style {
  color: #ff9933;
  cursor: pointer;
  font-size: 30px
}

.contact-submit {
  background-color: #C2212B;
  border: none;
  color: #fff;
}

.breadcrumb-item-abc {
  font-weight: 700;
  color: #888888
}

.mb-4{
  margin-bottom: 40px;
}

.width45{
  width: 45%;
}

.width20{
  width: 20%;
}

.add-height{
  height: 45px;
}

.mt-0{
margin-top: 0px;
}

.radio-select{
  margin-top: -10px;
}

.active-order-img {
  max-width: 160px;
  min-width: 160px;
  min-height: 140px;
  max-height: 140px;
}

.breadcrumb-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.breadcrumb-item-active {
  font-weight: 700;
  color: #B12926;
  font-size: 15px;
}

.thankYouCont {
  /* display: flex;
width: 100%;
max-width: 50%; */
  /* margin: 50px; */
  text-align: center;
  /* padding: 50px auto; */
  background-color: lightgray;
}

.column-table{
  margin-left: 20px;
  font-size: 20px;
}

.mrp{
  text-decoration:line-through;
}

.thankuInnerCon {
  /* margin: 100px; */
  padding: 20px;
  /* width:50%; */
  text-align: center;
  margin-left: 500px;
  margin-right: 500px;
  margin-top: 30px;

  background-color: white;
}

.mt-4{
  margin-top: 40px;
}

.marginTop {
  margin-top: 30px;
}

.pointer {
  cursor: pointer;
}

.card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
  transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
  cursor: pointer;
}

.bannerBtn{
  margin-left: 0%;
  text-align: left;
}

.arrow-icon{
  width: 20px;
  height: 100%;
}

.bold {
  font-weight: bold;
}

.PaymentMethodCont{
  width: max-content;
}

.PaymentMethod {
  width: 100%;
  display: grid;
  grid-template-columns: 15% 80%;
  grid-template-rows: auto auto;
  margin-top: 0.7rem;
}

.PaymentMethod label {
  color: gray;
  padding-left: 0.5rem;
}

.PaymentMethod span {
  display: flex;
  justify-content: left;
  align-items: center;
}

.PaymentMethod span input {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-top: -5px;
}

.bolder {
  font-weight: 900;
}

.product-name{
  font-size: 30px;
  font-weight: bold;
}

.font-sans{
 font-family: sans-serif;
}

.text-danger{
  color: red;
}

.RedText {
  color: #B12926;
}

.wish-dash-cartbtn{
  background: #fff; 
  border-radius: 50%; 
  color: #000;
}

.wish-dash-cartbtn-bg{
  background-color: #B12926; 
  border-radius: 20px; 
  font-size: 12px; 
  padding-left: 20px
}

.wrapper {
  overflow: auto;
  height: 50px;
  max-width: 100%;

  border: 1px solid red;
}
.content {
  overflow-y: auto;
}

.small {
  font-size: 11px;
  color: #888888
}

.small-text{
  font-size: 14px;
  color: #888888;
  font-weight: 600;
}

.whiteText {
  color: #fff;
}

.table-header{
  background: #FBFBFB; 
  color: #666666;
}

.table-body{
  color:#888888
}


.margin-left-right5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-left-right10 {
  margin-left: 10px;
  margin-right: 10px;
}

.hiddenMobile{
  display: block;
}

.center {
  text-align: center;
  text-align: -webkit-center;
  justify-content: center;
}

.margin-top-bottom {
  margin-top: 20px;
  margin-bottom: 20px;
}

.address-model-block .modal-dialog {
  min-width: 950px;
}

.mt-4{
  margin-top: 40px;
}

.mt-1{
  margin-top: 10px;
}

.thankYouCont {
  /* display: flex;
width: 100%;
max-width: 50%; */
  /* margin: 50px auto; */
  text-align: center;
  padding: 50px auto;
  /* justify-content: center; */
  font-family: 'Sarabun', sans-serif;
}

.ml2{
  margin-left: 10px;
}

.mt-2{
  margin-top: 20px;
}

.mt-3{
  margin-top: 30px;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.dash-btn{
  margin-top:20px; 
  margin-bottom:20px;
  font-size:15px;
  background-color:#3399CC;
  color: #FFE7E7;
  border: none;
  padding: 20px;
  padding-top:5px ;
  padding-bottom: 5px;
  border-radius: 5px;
}

.pl-3{
    padding-left: 30px;
}

.padding50 {
  padding: 50px;
}

.flex {
  display: flex
}

.ModalStyle{
  top:50px;
  background-color: #FBFBFB;
 max-height: 50px;
  left:50%
}

.font12{
  font-size: 12px;
}

.font15{
  font-size: 15px;
}

.input {
  display: block;
  min-width: 100%;
  margin-top: 1em;
  /* margin-bottom: 1em; */
  padding: 1em;
  /* width: 35em; */
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
}

.mt-2{
  margin-top :20px;
}

.mt-5{
  margin-top: 50px;
}

.dropdownX {
  width: 40%;
  /* min-width: 0; */
  /* max-width: 430px; */
  /* height: 100%; */
  max-height: 250px;
  overflow-x: hidden;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  visibility: hidden;
  position: absolute;
  z-index: 10;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  margin-top: 1px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.breadcrumb>li+li:before {
  content: "" !important;
}

.fullWidth {
  width: 100%;
}

a {
  text-decoration: none !important;
  color: #000 !important;
}

.breadcrumb-icon {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .breadcrumb {
    font-size: 10px;
  }

  .breadcrumb-item+.breadcrumb-item {
    padding-left: 0;
  }

  .hiddenMobile{
    display: none;
  }

  img {
    width: 16px;
    height: 11px;
    vertical-align: middle;
  }

  .nopadding-mobile{
    padding: 0px;
  }

  .breadcrumb {
    letter-spacing: 1px !important;
  }

  .breadcrumb>* div {
    max-width: 60px;
  }

  .bannerBtn{
    margin-left: 30%;
  }

}






.col-xs-offset-right-12 {
  margin-right: 100%;
}

.col-xs-offset-right-11 {
  margin-right: 91.66666667%;
}

.col-xs-offset-right-10 {
  margin-right: 83.33333333%;
}

.col-xs-offset-right-9 {
  margin-right: 75%;
}

.col-xs-offset-right-8 {
  margin-right: 66.66666667%;
}

.col-xs-offset-right-7 {
  margin-right: 58.33333333%;
}

.col-xs-offset-right-6 {
  margin-right: 50%;
}

.col-xs-offset-right-5 {
  margin-right: 41.66666667%;
}

.col-xs-offset-right-4 {
  margin-right: 33.33333333%;
}

.col-xs-offset-right-3 {
  margin-right: 25%;
}

.col-xs-offset-right-2 {
  margin-right: 16.66666667%;
}

.col-xs-offset-right-1 {
  margin-right: 8.33333333%;
}

.col-xs-offset-right-0 {
  margin-right: 0;
}

@media (min-width: 768px) {
  .col-sm-offset-right-12 {
    margin-right: 100%;
  }

  .col-sm-offset-right-11 {
    margin-right: 91.66666667%;
  }

  .col-sm-offset-right-10 {
    margin-right: 83.33333333%;
  }

  .col-sm-offset-right-9 {
    margin-right: 75%;
  }

  .col-sm-offset-right-8 {
    margin-right: 66.66666667%;
  }

  .col-sm-offset-right-7 {
    margin-right: 58.33333333%;
  }

  .col-sm-offset-right-6 {
    margin-right: 50%;
  }

  .col-sm-offset-right-5 {
    margin-right: 41.66666667%;
  }

  .col-sm-offset-right-4 {
    margin-right: 33.33333333%;
  }

  .col-sm-offset-right-3 {
    margin-right: 25%;
  }

  .col-sm-offset-right-2 {
    margin-right: 16.66666667%;
  }

  .col-sm-offset-right-1 {
    margin-right: 8.33333333%;
  }

  .col-sm-offset-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .col-md-offset-right-12 {
    margin-right: 100%;
  }

  .col-md-offset-right-11 {
    margin-right: 91.66666667%;
  }

  .col-md-offset-right-10 {
    margin-right: 83.33333333%;
  }

  .col-md-offset-right-9 {
    margin-right: 75%;
  }

  .col-md-offset-right-8 {
    margin-right: 66.66666667%;
  }

  .col-md-offset-right-7 {
    margin-right: 58.33333333%;
  }

  .col-md-offset-right-6 {
    margin-right: 50%;
  }

  .col-md-offset-right-5 {
    margin-right: 41.66666667%;
  }

  .col-md-offset-right-4 {
    margin-right: 33.33333333%;
  }

  .col-md-offset-right-3 {
    margin-right: 25%;
  }

  .col-md-offset-right-2 {
    margin-right: 16.66666667%;
  }

  .col-md-offset-right-1 {
    margin-right: 8.33333333%;
  }

  .col-md-offset-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .col-lg-offset-right-12 {
    margin-right: 100%;
  }

  .col-lg-offset-right-11 {
    margin-right: 91.66666667%;
  }

  .col-lg-offset-right-10 {
    margin-right: 83.33333333%;
  }

  .col-lg-offset-right-9 {
    margin-right: 75%;
  }

  .col-lg-offset-right-8 {
    margin-right: 66.66666667%;
  }

  .col-lg-offset-right-7 {
    margin-right: 58.33333333%;
  }

  .col-lg-offset-right-6 {
    margin-right: 50%;
  }

  .col-lg-offset-right-5 {
    margin-right: 41.66666667%;
  }

  .col-lg-offset-right-4 {
    margin-right: 33.33333333%;
  }

  .col-lg-offset-right-3 {
    margin-right: 25%;
  }

  .col-lg-offset-right-2 {
    margin-right: 16.66666667%;
  }

  .col-lg-offset-right-1 {
    margin-right: 8.33333333%;
  }

  .col-lg-offset-right-0 {
    margin-right: 0;
  }
}

@media screen and (max-width:1200px) {
  .review-modal{
    width:90%
  }
  
}

.hide-in-desktop {
  display: none;
}

@media only screen and (max-width: 400px) {
  .BuyCourses-Banner {
    background-size: cover;
    margin-top: 8rem;
  }
  .filter-sidebar-title {
    font-family: 'Nunito';
    font-size: 20px !important;
    font-weight: 700 !important;
    padding: 20px;
    display: flex;
    justify-content:space-between;
  }
  .filter-accordian button {
    font-family: 'Nunito';
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .Side-Option-Contaier {
    display: none;
  }
  .BuyCards-Container {
    display: block;
    margin-left: 0rem;
  }
  .BuyCourses-Container {
    display: block;
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;
  }
  .BuyCourse-Card {
    width: 100%;
    margin-left: 0rem;
    margin-bottom: 1rem;
  }
  .hide-in-desktop {
    display: block;
  }
  .burger-btn {
    width: 100%;
    margin-left: 0rem;
    margin-bottom: 1rem;
    background-color: #eb4d4b !important;
    border: none !important;
    color: #FFFFFF !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: 'Roboto';
  }
  .mobile-sorting-pd {
    padding: 0rem !important;
  }

  .sorting-left-col {
    background: #f5f5f5;
    padding: 10px 0px;
  }
  .sorting-right-col {
    padding: 0px 10px;
  }

  .mobile-sorting .nav-link {
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: 'Nunito';
    padding: 15px;
  }

  .mobile-sorting .nav-pills .nav-link.active {
      color: blue !important;
      background-color: #fff !important;
      padding: 15px;
  }

  .sort-checkbox-pd {
    padding: 5px 10px;
  }

  .sorting-btn {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .filter-bg {
    background: #eb4d4b;
    color: #fff;
    font-weight: 600;
    font-family: 'Nunito';
    border: none;
    font-size: 20px;
  }

  .sorting-button {
    background: #f5f5f5;
    font-family: 'Nunito';
    padding: 10px;
    border: none;
    color: black;
    font-size: 20px;
    border-right: 1px solid grey;
    text-align: inherit;
  }

  .sorting-button i {
    margin-right: 10px;
  }

  .filter-button i {
    margin-right: 10px;
  }

  .filter-button {
    background: #f5f5f5;
    font-family: 'Nunito';
    padding: 10px;
    border: none;
    color: black;
    font-size: 20px;
    text-align: end;
  }

  .start-checkbox-pd {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    align-items: center;
  }


}

@media only screen and (max-width: 800px) {
  .BuyCourses-Banner {
    background-size: cover;
    margin-top: 8rem;
  }
  .burger-btn {
    width: 100%;
    margin-left: 0rem;
    margin-bottom: 1rem;
    background-color: #eb4d4b !important;
    border: none !important;
    color: #FFFFFF !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: 'Roboto';
  }
  .filter-sidebar-title {
    font-family: 'Nunito';
    font-size: 20px !important;
    font-weight: 700 !important;
    padding: 20px;
  }

  .filter-accordian button {
    font-family: 'Nunito';
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .Side-Option-Contaier {
    display: none;
  }
  .BuyCards-Container {
    display: block;
    margin-left: 0rem;
  }
  .BuyCourses-Container {
    display: block;
    margin-top: 2rem;
    width: 100%;
    padding: 1rem;
  }
  .BuyCourse-Card {
    width: 100%;
    margin-left: 0rem;
    margin-bottom: 1rem;
  }
  .hide-in-desktop {
    display: block;
  }
  .mobile-sorting-pd {
    padding: 0rem !important;
  }

  .sorting-left-col {
    background: #f5f5f5;
    padding: 10px 0px;
  }
  .sorting-right-col {
    padding: 0px 10px;
  }

  .mobile-sorting .nav-link {
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: 'Nunito';
    padding: 15px;
  }

  .mobile-hidden{
    display: none;
  }

  .mobile-sorting .nav-pills .nav-link.active {
      color: blue !important;
      background-color: #fff !important;
      padding: 15px;
  }

  .sort-checkbox-pd {
    padding: 5px 10px;
  }

  .sorting-btn {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .filter-bg {
    background: #eb4d4b;
    color: #fff;
    font-weight: 600;
    font-family: 'Nunito';
    border: none;
    font-size: 20px;
  }

  .sorting-button {
    background: #f5f5f5;
    font-family: 'Nunito';
    padding: 10px;
    border: none;
    color: black;
    font-size: 20px;
    border-right: 1px solid grey;
    text-align: inherit;
  }

  .sorting-button i {
    margin-right: 10px;
  }

  .filter-button i {
    margin-right: 10px;
  }

  .filter-button {
    background: #f5f5f5;
    font-family: 'Nunito';
    padding: 10px;
    border: none;
    color: black;
    font-size: 20px;
    text-align: end;
  }

  .start-checkbox-pd {
    display: flex;
    justify-content: space-around;
    padding: 5px;
    align-items: center;
  }
}