.container {
    background-color: black;
    font-family: montserrat;
    height: 100%;
    padding: 45px;
    padding-bottom: 0px;
    color: #fff
}

.heading {
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
}

.links {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    margin-top: 10px;
}

.logo {
    height: 77px;
    width: 181px;
}

.paylogo {
    height: 22px;
    width: 36px;
}

.aboutUs {
    display: flex;
    justify-content: center;
}

.text {
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
}

.copyright {
    background-color: #000;
    color: #fff;
    /* border-bottom: 1px solid #fff; */
}

.copyrightText {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    border-top: 1px solid #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #000;
}

.socialMedia {
    height: 44px;
    width: 44px;
}

.marginLeft {
    margin-left: 10px;
}

.Izharson {
    color: red;
    margin-left: 5px;
}

.reserve {
    color: #fff;
    margin-left: 5px;
}

.center{
    text-align: left;
}

@media screen and (max-width:880px) {
    .aboutUs {
        display: flex;
        margin-top: 40px;
        justify-content: flex-start;
    }

    .copyrightText {
        display: flex;
        justify-content: center;
        font-size: 10px;
        margin-top: 20px;
        border-top: 1px solid #fff;
        padding-top: 20px;
    }

    .container {
        background-color: black;
        font-family: montserrat;
        height: 100%;
        padding: 5px;
        padding-bottom: 0px;
        color: #fff;
        padding-top: 20px;
    }
    .center{
        text-align: center;
    }

}


@media screen and (max-width:990px) {
    .container {
        background-color: black;
        font-family: montserrat;
        height: 100%;
        padding: 5px;
        padding-bottom: 0px;
        color: #fff;
        padding-top: 20px;
    }

}